import HeroFilters from '@finn/auto-ui/ui-modules/Hero2/HeroFilters';
import { Features as Experiments, useIsABVariant } from '@finn/ua-featureflags';
import { HeroSlug } from '@finn/ui-cosmic';
import { cn, replaceStrKeys, useSessionInCookie } from '@finn/ui-utils';

import RawHtml from '~/components/RawHtml';

import CTAs from './CTAs';
import Features from './Features';
import ImageBlock from './ImageBlock';
import { Hero2CosmicMetadata } from './metaData';
import { ProsCons } from './ProsCons';
import Stats from './Stats';

type Props = {
  data: Hero2CosmicMetadata;
  slug: string;
};

const middleScreenContainerStyle = ` md:!w-[calc(100vw-4rem)] md:!max-w-[calc(100vw-4rem)]`;
const largeScreenContainerStyle = `lg:!w-[calc(50%+630px)] lg:!max-w-[calc(50%+630px)]`;

const Hero2 = ({ data, slug }: Props) => {
  const session = useSessionInCookie();
  const firstName = session?.user?.hs_first_name;
  const templateValueMap = {
    name: firstName ? `, ${firstName}` : '',
  };
  const isExpHPHeroFilters =
    useIsABVariant(Experiments.ExpHPHeroFilters) &&
    slug.startsWith(HeroSlug.HOME);

  const label = replaceStrKeys(data?.label || '', templateValueMap);
  const title = replaceStrKeys(data?.title || '', templateValueMap);
  const description = replaceStrKeys(data?.description || '', templateValueMap);

  return (
    <div
      data-testid="hero2"
      className="mx-auto my-0 w-full max-w-[2100px] overflow-hidden"
    >
      <div
        className={cn(
          'container my-0 ml-auto md:mr-0',
          middleScreenContainerStyle,
          largeScreenContainerStyle
        )}
      >
        <div className="grid grid-cols-1 gap-8 md:grid-cols-[560px,1fr] md:gap-12 lg:grid-cols-[560px,1fr]">
          <div className="my-8 w-full place-self-center md:col-start-1 md:col-end-2 md:m-12">
            <h1
              className="mobile-t1-semibold md:web-t1-semibold mb-8"
              data-cy="hero-title"
            >
              {label && <span className="body-16-semibold mb-2">{label}</span>}
              <span className="block">{title}</span>
            </h1>
            {description && (
              <div className="mb-6">
                <RawHtml html={description} useGlobalStyles fullWidthMedia />
              </div>
            )}
            <Features features={data.features} />
            <ProsCons pros={data.pros} cons={data.cons} />

            {isExpHPHeroFilters ? (
              <HeroFilters primaryCTA={data.primary_cta} />
            ) : (
              <CTAs
                primaryCTA={data.primary_cta}
                secondaryCTA={data.secondary_cta}
              />
            )}
            <Stats stats={data.stats} />
          </div>
          <div className="hidden md:block">
            <ImageBlock
              url={data.hero_image?.url}
              altText={data.hero_image_alt_text}
            />
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <ImageBlock
          url={data.hero_image?.url}
          altText={data.hero_image_alt_text}
        />
      </div>
    </div>
  );
};
export default Hero2;
