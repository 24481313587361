import { CheckCircleFilled } from '@finn/design-system/icons/check-circle-filled';
import { YesNoNo } from '@finn/design-system/icons/yes-no-no';
import React from 'react';
import { useIntl } from 'react-intl';

import { ProConItem } from './metaData';

export const ProsCons: React.FC<{ pros: ProConItem[]; cons: ProConItem[] }> = ({
  pros,
  cons,
}) => {
  const i18n = useIntl();
  if (!pros || !pros.length || !cons || !cons.length) {
    return null;
  }

  return (
    <div className="bg-snow body-14-light mb-5 rounded px-6 pb-2 pt-6 sm:flex">
      <div className="pb-4 sm:w-1/2 sm:pb-0">
        <div className="body-14-semibold">
          {i18n.formatMessage({ id: 'general.pros' })}
        </div>
        <ul className="m-0 list-none p-0 pt-4">
          {pros.map(({ text }) => (
            <li key={text} className="flex items-center pb-4">
              <CheckCircleFilled className="mr-2 w-4" /> <span>{text}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="sm:w-1/2">
        <div className="body-14-semibold">
          {i18n.formatMessage({ id: 'general.cons' })}
        </div>
        <ul className="m-0 list-none p-0 pt-4">
          {cons.map(({ text }) => (
            <li key={text} className="flex items-center pb-4">
              <YesNoNo className="mr-2 w-4" /> <span>{text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
