import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { FilterKey } from '@finn/ua-vehicle';
import { useCallback } from 'react';

type FilterCategoryKey =
  | Omit<
      FilterKey,
      | FilterKey.MIN_PRICE
      | FilterKey.MAX_PRICE
      | FilterKey.AVAILABLE_FROM
      | FilterKey.AVAILABLE_TO
    >
  | 'price'
  | 'availability'
  | 'model_more'
  | 'miscellaneous';

export function useTrackFilterCategoryClicked(
  filterCategoryKey: FilterCategoryKey
) {
  return useCallback(
    (isOpen: boolean, properties?: any) => {
      if (isOpen) {
        interactionTrackingEvent(TrackingEventName.FILTER_CATEGORY_CLICKED, {
          type: filterCategoryKey,
          ...properties,
        });
      } else {
        interactionTrackingEvent(TrackingEventName.FILTER_CATEGORY_CLOSED, {
          type: filterCategoryKey,
          ...properties,
        });
      }
    },
    [filterCategoryKey]
  );
}

export function trackFilterOptionClicked(
  filterCategoryKey: FilterCategoryKey,
  value: unknown
) {
  interactionTrackingEvent(TrackingEventName.FILTER_OPTION_CLICKED, {
    type: filterCategoryKey,
    value,
  });
}

export function trackFilterDrawerOpened() {
  interactionTrackingEvent(TrackingEventName.FILTER_DRAWER_OPENED, {});
}

export function trackFilterDrawerClosed() {
  interactionTrackingEvent(TrackingEventName.FILTER_DRAWER_CLOSED, {});
}

export function trackFilterTagRemoved() {
  interactionTrackingEvent(TrackingEventName.FILTER_TAG_REMOVED, {});
}

export function trackFilterTagsScrolled() {
  interactionTrackingEvent(TrackingEventName.FILTER_TAGS_SCROLLED, {});
}

export function trackFiltersCleared() {
  interactionTrackingEvent(TrackingEventName.FILTERS_CLEARED, {});
}
