import { useMediaQuery } from '@finn/design-system/helpers/media';
import { CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import React from 'react';

type Props = {
  primaryCTA: CTAData;
  secondaryCTA?: CTAData;
  onSecondaryCTAClick?: () => void;
  fullWidth?: boolean;
};

const CTAs: React.FC<Props> = ({
  primaryCTA,
  secondaryCTA,
  onSecondaryCTAClick = () => {},
  fullWidth = false,
}) => {
  const isMobile = useMediaQuery('sm');
  if (!primaryCTA?.metadata && !secondaryCTA?.metadata) return null;

  return (
    <div className={cn('flex flex-wrap gap-4', isMobile ? 'mb-6' : 'mb-8')}>
      {primaryCTA?.metadata && (
        <CTA
          data={primaryCTA.metadata}
          parentBG="light"
          fullWidth={fullWidth}
        />
      )}
      {secondaryCTA?.metadata && (
        <CTA
          data={secondaryCTA.metadata}
          parentBG="light"
          onCTAClick={onSecondaryCTAClick}
        />
      )}
    </div>
  );
};
export default CTAs;
