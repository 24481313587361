import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import React from 'react';

type Props = {
  url: string;
  altText?: string;
};

const ImageBlock: React.FC<Props> = ({ url, altText }) => {
  const cloudinaryURL = getCloudinaryImgUrl(url, {
    w: 480,
    dpr: 2,
  });
  const cloudinarySrcSet = getCloudinarySrcSet(url, [480, 800, 1200, 1600]);

  return (
    <img
      className="block h-[calc(100vh-160px)]  max-h-[345px] w-full bg-cover bg-center object-cover sm:max-h-[480px] md:col-start-2 md:col-end-3 md:max-h-[702px] md:min-h-[472px]"
      srcSet={cloudinarySrcSet}
      src={cloudinaryURL}
      alt={altText}
    />
  );
};
export default ImageBlock;
